import React, { useState, useContext, useEffect, Component } from "react";
import "./PTBLogin.css";
import { Button, Form, Col, Modal, Row } from "react-bootstrap";
import { useLocation, Navigate } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import ImageHelper from "../../components/ImageHelper";

const DemoLogin = (props) => {
  const [password, setPassword] = useState("");
  const [failed, setFailed] = useState(false);

  const verifyLogin = () => {
    password === "Y~2u[:Xm<GQN3C"
      ? props.setAuthenticated(true)
      : setFailed(true);
  };

  return (
    <div className="login-whole-page">
      <Modal className="login-modal" show={true} centered>
        <Modal.Header className="login-modal-logo">
          <ImageHelper mode="saiclogo" width="35%" onClick={() => {}} />
        </Modal.Header>

        <Modal.Header className="login-modal-ro">
          <ImageHelper mode="readyone" width="80%" onClick={() => {}} />
        </Modal.Header>

        <Modal.Header className="login-modal-header">
          <Modal.Title>Enter the password you recieved via email.</Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body text-center">
          <br />
          <Form.Group as={Col}>
            <Form.Label className="login-text-white">Password</Form.Label>
            <Form.Control
              onChange={(e) => setPassword(e.target.value)}
              as="input"
              type="password"
              className="my-1 mr-sm-2"
            ></Form.Control>
          </Form.Group>
          {failed && <div className="fail-text">Incorrect Password</div>}
          <br />
        </Modal.Body>

        <Modal.Footer className="login-modal-footer">
          <Button
            variant="danger"
            className="login-modal-button"
            onClick={() => verifyLogin()}
          >
            Login
          </Button>
          <br />
          <br />
        </Modal.Footer>

        <Modal.Footer className="login-modal-footer-disc"></Modal.Footer>
      </Modal>
    </div>
  );
};

export default DemoLogin;
