import React, { useEffect, useState, useContext } from "react";
import styles from "./DashboardForm.module.css";
import Modal from "../Modal/Modal";
import {
  addDashboard,
  getDashboards,
  deleteDashboard,
} from "../../../../services/ReadyOneAPI";
import { ToastContext } from "../../../../layout";
import { format } from "date-fns-tz";

export default function DashboardForm({ show, close }) {
  const [startDate, setStartDate] = useState(
    format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx", {
      timeZone: "America/New_York",
    })
  );
  const [endDate, setEndDate] = useState(
    format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx", {
      timeZone: "America/New_York",
    })
  );
  const [title, setTitle] = useState("");
  const [report, setReport] = useState("");
  const [dashboards, setDashboards] = useState([]);
  const { notifySuccess, notifyError } = useContext(ToastContext);

  const handleSubmit = () => {
    console.log("Pressed")
    addDashboard({ title, report }).then((result) => {
        let text = JSON.parse(result).message
        notifySuccess(text.replace(/^"|"$/g, ""));
        getDashboards().then((result) => {
          setDashboards(result);
          setTitle("")
          setReport("")
        });
      })
      .catch((err) => {
        notifyError(err);
      });
  };

  const handleDelete = (id) => {
    deleteDashboard({title: id}).then((result) => {
      let text = JSON.parse(result).message
      notifySuccess(text.replace(/^"|"$/g, ""));
      getDashboards().then((result) => {
        setDashboards(result);
      });
    });
  };

  useEffect(() => {
    getDashboards().then((result) => {
      setDashboards(result);
    });
  }, [])

  return (
    <Modal
      id="dashboard-modal"
      show={show}
      title={"New Dashboard"}
      buttonText={"Create Dashboard"}
      onClick={() => {
        handleSubmit();
      }}
      hideModal={close}
    >
      <div className={styles.notifHolder}>
        <div className={styles.form}>
          <label>
            Title:
            <input
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={styles.inputField}
            />
          </label>
          <label>
            Report URL:
            <input
              name="report"
              value={report}
              onChange={(e) => setReport(e.target.value)}
              className={styles.inputField}
            />
          </label>
          </div>
          <label>
          Dashboards:
          <table className={styles.dashboardTable}>
            <thead>
              <tr>
                <th>Dashboard</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dashboards.map((dashboard, index) => (
                <tr key={index}>
                  <td>{dashboard.title}</td>
                  <td>
                    <button
                      onClick={() => handleDelete(dashboard.title)}
                      className={styles.deleteButton}
                    >
                      X
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </label>
        
      </div>
    </Modal>
  );
}
