import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import Select from "react-select";
import { sendEmail } from "../../../services/ReadyOneAPI";
import { getModalData, getCategories, getProjects } from "../../../services/TeamworkCloudAdminAPI";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import "../modals.css";

const TWCUserPermissions = (props) => {
  const [requesterEmail, setRequesterEmail] = useState(
    props.requesterEmail || ""
  );
  const [shortForm] = useState(props.shortform || "");
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [instances, setInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getCategories().then((response) => {
      console.log(response);
      setInstances(response.instances)
    });

    getModalData().then((response) => {
      setPermissions(response.permissions);
    });
  }, []);

  useEffect(() => {
    if(selectedInstance){
      getCategories(selectedInstance.value).then((response) => {
        console.log(response);
        setUsers(response.users);
        setCategories(response.projects);
        //setInstances(response.instances)
      });
    }
    
  }, [selectedInstance])

  useEffect(() => {
    setProjects([]);
    if(selectedCategories !== ""){
      getProjects(selectedCategories.value, selectedInstance.value).then(result => {
        setProjects(result.projects)
      })
    }
  },[selectedCategories])

  const sendNewUserEmail = () => {
    setLoading(true);
    let payload = {
      requestTemplate: "twc-update-permissions",
      instance: selectedInstance.value,
      category: selectedCategories.value,
      projects: selectedProjects.map(proj => proj.value),
      users: selectedUsers.map(user => user.value),
      permissions: selectedPermissions.map((p) => p.value),
      requesterEmail: requesterEmail,
    }
    
    sendEmail(payload).then(res => {
        setMessage(getRequestedTemplate(res.body));
        setLoading(false);
        setDone(true);
    })
    //props.secondButton(false);
  };

  const closeModal = () => {
    setMessage("");
    setSelectedInstance("");
    setSelectedCategories("");
    setSelectedProjects([]);
    setSelectedUsers([]);
    setSelectedPermissions([]);
    setLoading(false);
    setDone(false);
    props.secondButton(false);
  }

  const getRequestedTemplate = (body) => {
    return <div className="result-text">
      <div className="result-requested">Requested</div>
      <div className="result-primary">Updated user permissions have been requested</div>
      <div className="result-secondary">{body}</div>
    </div>
  }

  const getErrorTemplate = (name) => {
    return <div className="result-text">
      <div className="result-error">Error</div>
      <div className="result-primary">There was a problem creating category <a>{name}</a></div>
      <div className="result-secondary">Please verify that category {name} does not already exist. Contact an administrator if the problem persists.</div>
    </div>
  }

  const override = css`
  display: block;
  margin: auto;
  margin-top: 40%;
`;

  return (
    <Modal show={props.show} onHide={() => closeModal()} centered>
      <Modal.Header className="confirm-modal-header">
        <Modal.Title className={props.mode}>
          Teamwork Cloud User Permissions
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="confirm-modal-body">
      {done ? (message) : loading && !done ? (
          <RingLoader
            size={80}
            color={"#ffffff"}
            loading={loading}
            size="100px"
            css={override}
          />
        ) :
        (
        <>
        <Form.Group as={Col}>
          <Form.Label className="text-white">Instance</Form.Label>
          <Select
            options={instances}
            isMulti={false}
            onChange={(event) => {
              setSelectedInstance(event);
            }}
          />
        </Form.Group>
        <br />
        <Form.Group as={Col}>
          <Form.Label className="text-white">Users</Form.Label>
          <Select
            options={users}
            isMulti={true}
            onChange={(event) => {
              setSelectedUsers(event);
            }}
          />
        </Form.Group>
        <br />
        <Form.Group as={Col}>
          <Form.Label className="text-white">Categories</Form.Label>
          <Select
            options={categories}
            // isMulti={true}
            onChange={(event) => {
              setSelectedCategories(event);
              // onCategorySelect(event);
            }}
          />
        </Form.Group>
        <br />
        <Form.Group as={Col}>
          <Form.Label className="text-white">Projects</Form.Label>
          <Select
            options={projects}
            isMulti={true}
            isDisabled={projects.length === 0}
            onChange={(event) => {
              setSelectedProjects(event);
            }}
          />
        </Form.Group>
        <br />
        <Form.Group as={Col}>
          <Form.Label className="text-white">Permissions</Form.Label>
          <Select
            options={permissions}
            isMulti={true}
            onChange={(event) => {
              setSelectedPermissions(event);
            }}
          />
        </Form.Group>
        <br />
        </>
        )}
      </Modal.Body>

      <Modal.Footer className="confirm-modal-footer">
      {!loading && !done && <Button
          variant="primary"
          className="modal-button"
          onClick={() => sendNewUserEmail()}
        >
          Submit
        </Button>}
        <Button
          variant="danger"
          className="modal-button"
          onClick={() => closeModal()}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TWCUserPermissions;
