import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import Select from "react-select";
import { sendEmail } from "../../../services/ReadyOneAPI";
import { getELMData } from "../../../services/ELMAdminAPI";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import "../modals.css";

const ELMCreateProject = (props) => {
  const [requesterEmail, setRequesterEmail] = useState(
    props.requesterEmail || ""
  );
  const [shortForm, setShortForm] = useState(props.shortform || "");
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [applications, setApplications] = useState([]);
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getELMData().then((response) => {
      setProjects(response.body.projects);
      setPermissions(response.body.permissions);
      setApplications(response.body.applications);
      setUsers(response.body.users);
    });
  }, []);

  const sendNewUserEmail = () => {
      setLoading(true);
    let payload = {
      requestTemplate: "elm-create-project",
      project: projectName,
      users: selectedUsers.map((u) => u.value),
      applications: selectedApplications.map((a) => a.value),
      requesterEmail: requesterEmail,
    };

    sendEmail(payload).then((res) => {
      setMessage(getRequestedTemplate(res.body));
      setLoading(false);
      setDone(true);
    });
  };

  const getRequestedTemplate = (body) => {
    return (
      <div className="result-text">
        <div className="result-requested">Requested</div>
        <div className="result-primary">
          The project has been requested
        </div>
        <div className="result-secondary">{body}</div>
      </div>
    );
  };

  const getErrorTemplate = (name) => {
    return (
      <div className="result-text">
        <div className="result-error">Error</div>
        <div className="result-primary">
          There was a problem creating category <a>{name}</a>
        </div>
        <div className="result-secondary">
          Please verify that category {name} does not already exist. Contact an
          administrator if the problem persists.
        </div>
      </div>
    );
  };

  const closeModal = () => {
    setMessage("");
    setSelectedUsers([]);
    setSelectedApplications([]);
    setProjectName("");
    setLoading(false);
    setDone(false);
    props.secondButton(false);
  };

  const override = css`
    display: block;
    margin: auto;
    margin-top: 40%;
  `;

  return (
    <Modal show={props.show} onHide={() => closeModal()} centered>
      <Modal.Header className="confirm-modal-header">
        <Modal.Title className={props.mode}>
          IBM ELM Create Project
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="confirm-modal-body">
        {done ? (
          message
        ) : loading && !done ? (
          <RingLoader
            size={80}
            color={"#ffffff"}
            loading={loading}
            size="100px"
            css={override}
          />
        ) : (
          <>
            <Form.Group as={Col}>
              <Form.Label className="text-white">Project Name</Form.Label>
              <Form.Control
                onChange={(e) => setProjectName(e.target.value)}
                as="input"
                className="my-1 mr-sm-2"
                value={projectName}
              ></Form.Control>
            </Form.Group>
            <br />
            <Form.Group as={Col}>
              <Form.Label className="text-white">Applications</Form.Label>
              <Select
                options={applications}
                isMulti={true}
                onChange={(event) => {
                  setSelectedApplications(event);
                }}
              />
            </Form.Group>
            <br />
            <Form.Group as={Col}>
              <Form.Label className="text-white">Users</Form.Label>
              <Select
                options={users}
                isMulti={true}
                onChange={(event) => {
                  setSelectedUsers(event);
                }}
              />
            </Form.Group>
            <br />
          </>
        )}
      </Modal.Body>

      <Modal.Footer className="confirm-modal-footer">
      {!loading && !done && <Button
          variant="primary"
          className="modal-button"
          onClick={() => sendNewUserEmail()}
        >
          Submit
        </Button>}
        <Button
          variant="danger"
          className="modal-button"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ELMCreateProject;
