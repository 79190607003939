import React from "react";
import { FolderItem, FileItem } from "./FileItems";
import styles from "./Folder.module.css";

export default function Folder({ activeMenu, setActiveMenu, folder }) {
  return (
    <>
      <div
        className={`${styles.explorer} ${
          folder.key === activeMenu ? styles.enter : styles.exit
        }`}
      >
        {folder.folders.map((f) => (
          <FolderItem file={f} setActiveMenu={setActiveMenu} />
        ))}

        {folder.files.map((f) => (
          <FileItem file={f} path={f.path} />
        ))}
      </div>

      {folder.folders.map((f) => (
        <Folder
          folder={f}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          onEnter={() => null}
        />
      ))}
    </>
  );
}
