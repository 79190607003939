import React, { useEffect, useState, useRef } from "react";
import styles from "./Quicklinks.module.css";
import { getTable } from "../../../services/ReadyOneAPI";
import { useOnClickOutside } from "../../../hooks";

export default function Quicklinks({ setPath, path, show, close }) {
  const [links, setLinks] = useState([]);
  const [oldPath, setOldPath] = useState(path);
  const [hasRendered, setHasRendered] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => show && close());

  useEffect(() => {
    getTable("QuickLinks").then((result) => {
      setLinks(result.body.Items);
    });
  }, []);

  useEffect(() => path !== "/Quicklinks" && setOldPath(path), [path]);

  useEffect(() => {
    show && setHasRendered(true);
    setPath(show ? "/Quicklinks" : oldPath);
  }, [show]);

  return (
    <div
      ref={ref}
      className={`${styles.dropdown} ${show ? styles.open : ""} ${
        hasRendered ? "" : styles.preventAnimation
      }`}
    >
      <ul className={styles.list}>
        {links.map((link) => (
          <li
            className={styles.item}
            onClick={() => {
              window.open(link.LinkURL);
            }}
          >
            {link.LinkName}
          </li>
        ))}
      </ul>
    </div>
  );
}
