import React, { useState, useEffect } from "react";
import styles from "./HowTo.module.css";
import { getHowTo, search } from "../../services/ReadyOneAPI";
import RingLoader from "react-spinners/RingLoader";
import { Header } from "./Header/";
import { Footer } from "./Footer/";
import { Content, getFileNameFromKey } from "./Content/";

const HowTo = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuStackState, setMenuStackState] = useState([]);
  const [filePath, setFilePath] = useState("");

  useEffect(() => {
    setLoading(true);
    getHowTo().then((response) => {
      setData(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    let menuStack = menuStackState;
    menuStack.push(activeMenu);
    setMenuStackState(menuStack);
    setFilePath(
      menuStack.map((s) => {
        if (s !== "main")
          return (
            <div onClick={() => clickBack(s)}>
              {" → " + getFileNameFromKey(s)}
            </div>
          );
      })
    );
  }, [activeMenu]);

  const getSearchResults = (event) => {
    let searchTerm = event.target.value;
    setSearchValue(searchTerm);
    console.log(searchTerm);
    if (searchTerm) {
      search(searchTerm).then((result) => setSearchResults(result));
    }
  };

  const clickBack = (menu) => {
    if (menu === activeMenu) return;
    let menuStack = menuStackState;
    if (menu) {
      let temp = menuStack.pop();
      while (menu !== temp) temp = menuStack.pop();
      menuStack.push(temp);
      menuStack.push("temporary value");
    }
    menuStack.pop();
    setFilePath(
      menuStack.map((s) => {
        if (s !== "main")
          return (
            <div onClick={() => clickBack(s)}>
              {" → " + getFileNameFromKey(s)}
            </div>
          );
      })
    );
    if (menuStack.length < 1) menuStack.push("main");
    setActiveMenu(menuStack.pop());
    if (menuStack.length < 1) menuStack.push("main");
    setMenuStackState(menuStack);
  };

  return (
    <>
      {loading ? (
        <RingLoader
          size={80}
          color={"#ffffff"}
          loading={loading}
          size="100px"
        />
      ) : (
        <>
          <div className={styles.container}>
            <Header
              clickBack={clickBack}
              activeMenu={activeMenu}
              getSearchResults={getSearchResults}
              filePath={filePath}
              searchValue={searchValue}
            />

            <Content
              searchValue={searchValue}
              searchResults={searchResults}
              data={data}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
            />

            <Footer activeMenu={activeMenu} />
          </div>
        </>
      )}
    </>
  );
};
export default HowTo;
