import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { sendEmail } from "../../../services/ReadyOneAPI";
import Select from "react-select";
import TWCUserPermissions from "../TeamworkCloud/TWCUserPermissions";
import ELMUserPermissions from "../IBM/ELMUserPermissions";
import InnovatorUserPermissions from "../Innovator/InnovatorUserPermissions";
import "../modals.css";

const NewUserModal = (props) => {
  const [userEmail, setUserEmail] = useState("");
  const [requesterEmail, setRequesterEmail] = useState(props.requesterEmail);
  const [shortForm, setShortForm] = useState("");
  const [applications] = useState([
    { value: "twcloud", label: "Teamwork Cloud" },
    { value: "elm", label: "IBM ELM" },
    { value: "innovator", label: "Aras Innovator" },
  ]);
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [showTWC, setShowTWC] = useState(false);
  const [showELM, setShowELM] = useState(false);
  const [showAras, setShowAras] = useState(false);

  useEffect(() => {}, []);

  const sendNewUserEmail = () => {
    console.log(shortForm);

    props.secondButton(false);
    if (selectedApplications) {
      if (selectedApplications.map((app) => app.value).includes("twcloud")) {
        setShowTWC(true);
      } else if (selectedApplications.map((app) => app.value).includes("elm")) {
        setShowELM(true);
      } else if (
        selectedApplications.map((app) => app.value).includes("innovator")
      ) {
        setShowAras(true);
      }
    }

    var data = {};
    data.requestTemplate = "new-user";
    data.email = userEmail;
    data.shortformUsername = shortForm;
    data.requesterEmail = requesterEmail;
    sendEmail(data);
    props.secondButton(false);
  };

  const TWCNext = () => {
    props.secondButton(false);
    setShowTWC(false);
    if (selectedApplications.map((app) => app.value).includes("elm")) {
      setShowELM(true);
    } else if (
      selectedApplications.map((app) => app.value).includes("innovator")
    ) {
      setShowAras(true);
    }
  };

  const ELMNext = () => {
    props.secondButton(false);
    setShowELM(false);
    if (selectedApplications.map((app) => app.value).includes("innovator")) {
      setShowAras(true);
    }
  };

  const handleChange = (event) => {
    setSelectedApplications(event);
    console.log(selectedApplications);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.secondButton(false)}
        centered
      >
        <Modal.Header className="confirm-modal-header">
          <Modal.Title className={props.mode}>
            New User
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="confirm-modal-body">
          <Form.Group as={Col}>
            <Form.Label className="text-white">Email</Form.Label>
            <Form.Control
              onChange={(e) => setUserEmail(e.target.value)}
              as="input"
              className="my-1 mr-sm-2"
            ></Form.Control>
          </Form.Group>
          <br />
          <Form.Group as={Col}>
            <Form.Label className="text-white">Shortform User Name</Form.Label>
            <Form.Control
              onChange={(e) => setShortForm(e.target.value)}
              as="input"
              className="my-1 mr-sm-2"
            ></Form.Control>
          </Form.Group>
          <br />
          <Form.Label className="text-white">Application Access</Form.Label>
          <Select
            options={applications}
            isMulti={true}
            onChange={handleChange}
          />
          <br />
        </Modal.Body>

        <Modal.Footer className="confirm-modal-footer">
          <Button
            variant="primary"
            className="modal-button"
            onClick={() => sendNewUserEmail()}
          >
            Submit
          </Button>
          <Button
            variant="danger"
            className="modal-button"
            onClick={() => props.secondButton(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {showTWC && (
        <TWCUserPermissions
          show={showTWC}
          secondButton={() => {
            TWCNext();
          }}
          mode="confirm"
          shortform={shortForm}
          requesterEmail={requesterEmail}
        />
      )}
      {showELM && (
        <ELMUserPermissions
          show={showELM}
          secondButton={() => {
            ELMNext();
          }}
          mode="confirm"
          shortform={shortForm}
          requesterEmail={requesterEmail}
        />
      )}
      {showAras && (
        <InnovatorUserPermissions
          show={showAras}
          secondButton={() => {
            setShowAras(false);
          }}
          mode="confirm"
          shortform={shortForm}
          requesterEmail={requesterEmail}
        />
      )}
    </>
  );
};

export default NewUserModal;
