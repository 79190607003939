import React, {useContext} from "react";
import { useOuterClick } from "../../../../../../../hooks";
import { AccountContext } from "../../../../../../../auth";
import styles from "./LinkMenu.module.css";

export default function LinkMenu({ links, unblur, checked, getStreamingURL }) {
  const { internal } = useContext(AccountContext);

  const innerRef = useOuterClick((ev) => {
    unblur();
  });

  const handleOpen = (link) => {
    if (link.dynamic && !internal) {
      getStreamingURL(link);
    } else {
      window.open(link.link, undefined, "noreferrer noopener");
    }
  }

  return (
    <nav className={styles.menu}>
      <input
        checked={checked}
        className={styles.menuToggler}
        type="checkbox"
        onClick={() => unblur()}
        ref={innerRef}
      />
      <label for={styles.menuToggler}></label>

      <ul>
        {links.map((link) => (
          <li
            className={styles.menuItem}
            key={link.title}
            onClick={() => checked && handleOpen(link)}
          >
            <a className={styles.title}>{link.title}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
