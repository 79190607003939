const apiURL = "https://twc-api.readyone.net";
const apiKey = "ZHj2tQ0eQRa0rhOpKf1HRy6DKyRbxET8AgzZq2ic";

export const createCategory = async ({
  username,
  instance,
  projectName,
  managers,
  contributors,
  reviewers
}) => {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");
  console.log(instance);
  var raw = JSON.stringify({
    username,
    instance,
    projectName,
    managers,
    contributors,
    reviewers,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/create/category`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getProjects = async (categoryTitle, instance) => {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ categoryTitle: categoryTitle, instance: instance });
  console.log("category title: ", categoryTitle);
  console.log("instance: ", instance);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get/projects`, requestOptions)
    .then((response) => response.json())
    .then((result) => result.body)
    .catch((error) => console.log("error", error));
};

export function getModalData() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get/modaldata`, requestOptions)
    .then((response) => response.json())
    .then((body) => body.body);
}

export function getCategories(instance) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: JSON.stringify({instance: instance})
  };
  
  return fetch(`${apiURL}/get/categories`, requestOptions)
    .then((response) => response.json())
    .then((result) => result.body)
    .catch((error) => console.log("error", error));
}

export const getUserRoles = async (username) => {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");
  console.log("username: ", username);
  var raw = JSON.stringify({ username: username });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get/userroles`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};
