import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import Select from "react-select";
import { sendEmail } from "../../../services/ReadyOneAPI";
import { getInnovatorData } from "../../../services/InnovatorAdminAPI";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import "../modals.css";

const InnovatorUserPermissions = (props) => {
  const [requesterEmail, setRequesterEmail] = useState(
    props.requesterEmail || ""
  );
  const [instances, setInstances] = useState([]);
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [databases, setDatabases] = useState([]);
  const [selectedDatabases, setSelectedDatabases] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getInnovatorData().then((response) => {
      console.log(response)
      setInstances(response.body.instances);
      setDatabases(response.body.databases);
      setRoles(response.body.roles);
      setUsers(response.body.users);
    });
  }, []);

  const sendNewUserEmail = () => {
    setLoading(true);
    console.log(selectedInstances)
    let payload = {
      requestTemplate: "innovator-update-permissions",
      instance: selectedInstances.value,
      databases: selectedDatabases.map((d) => d.value),
      roles: selectedRoles.map((r) => r.value),
      users: selectedUsers.map((u) => u.value),
      requesterEmail: props.requesterEmail,
    };

    sendEmail(payload).then((res) => {
      setMessage(getRequestedTemplate(res.body));
      setLoading(false);
      setDone(true);
    });
  };

  const getRequestedTemplate = (body) => {
    return (
      <div className="result-text">
        <div className="result-requested">Requested</div>
        <div className="result-primary">
          The permission updates have been requested
        </div>
        <div className="result-secondary">{body}</div>
      </div>
    );
  };

  const getErrorTemplate = (name) => {
    return (
      <div className="result-text">
        <div className="result-error">Error</div>
        <div className="result-primary">
          There was a problem creating category <a>{name}</a>
        </div>
        <div className="result-secondary">
          Please verify that category {name} does not already exist. Contact an
          administrator if the problem persists.
        </div>
      </div>
    );
  };

  const override = css`
  display: block;
  margin: auto;
  margin-top: 40%;
`;

  return (
    <Modal show={props.show} onHide={() => props.secondButton(false)} centered>
      <Modal.Header className="confirm-modal-header">
        <Modal.Title className={props.mode}>
          Aras Innovator User Permissions
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="confirm-modal-body">
        {done ? (
          message
        ) : loading && !done ? (
          <RingLoader
            size={80}
            color={"#ffffff"}
            loading={loading}
            size="100px"
            css={override}
          />
        ) : (
          <>
            <Form.Group as={Col}>
              <Form.Label className="text-white">Instance</Form.Label>
              <Select
                options={instances}
                isMulti={false}
                onChange={(event) => {
                  setSelectedInstances(event);
                }}
              />
            </Form.Group>
            <br />
            <Form.Group as={Col}>
              <Form.Label className="text-white">Databases</Form.Label>
              <Select
                options={databases}
                isMulti={true}
                onChange={(event) => {
                  setSelectedDatabases(event);
                }}
              />
            </Form.Group>
            <br />
            <Form.Group as={Col}>
              <Form.Label className="text-white">Users</Form.Label>
              <Select
                options={users}
                isMulti={true}
                onChange={(event) => {
                  setSelectedUsers(event);
                }}
              />
            </Form.Group>
            <br />
            <Form.Group as={Col}>
              <Form.Label className="text-white">Roles</Form.Label>
              <Select
                options={roles}
                isMulti={true}
                onChange={(event) => {
                  setSelectedRoles(event);
                }}
              />
            </Form.Group>
            <br />
          </>
        )}
      </Modal.Body>

      <Modal.Footer className="confirm-modal-footer">
      {!loading && !done && <Button
          variant="primary"
          className="modal-button"
          onClick={() => sendNewUserEmail()}
        >
          Submit
        </Button>}
        <Button
          variant="danger"
          className="modal-button"
          onClick={() => props.secondButton(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InnovatorUserPermissions;
