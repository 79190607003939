import styles from "./Dashboard.module.css";
import React, {useState} from 'react'

const Dashboard = ({ title, link, update }) => {

  return (
    <div className={styles.frame}>
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
        <button onClick={() => update()} className={styles.switchButton}>Switch Dashboard</button>
      </div>
      <iframe
        title={title}
        className={styles.reportFrame}
        src={link}
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default Dashboard;
