import React, { useState, useContext, useEffect } from "react";
import "./PTBPage.css";
import DemoNavbar from "./PTBNavbar";
import DemoFooter from "./PTBFooter";
import { Container, Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import { AccountContext } from "../../auth";
import ImageHelper from "../../components/ImageHelper";
import DemoLogin from "./PTBLogin";
import { Link } from "react-router-dom";

const PTBPage = (props) => {
  const { authenticated } = useContext(AccountContext);
  const [playerDimension, setPlayerDimension] = useState({
    height: "25rem",
    width: "40rem",
  });
  const [videoTextClass, setVideoTextClass] = useState("video-player-text");
  const [access, setAccess] = useState(authenticated);

  useEffect(() => {
    //props.setPath(window.location.pathname);
  }, []);

  const videoStarted = () => {
    setPlayerDimension({ height: "40rem", width: "100%" });
    setVideoTextClass("video-player-text-playing");
  };

  const videoPaused = () => {
    setPlayerDimension({ height: "25rem", width: "40rem" });
    setVideoTextClass("video-player-text");
  };

  return (
    <>
      {!authenticated && <DemoNavbar />}
      {access ? (
        <Container className="getting-started-whole-box">
          <Row className="getting-started-title">
            Welcome To
            <ImageHelper
              mode="saiclogo"
              width="3.44em"
              className="navbarbrand"
              onClick={() => {}}
            />
            ReadyOne!
          </Row>

          <Row className="getting-started-footer">
            <Row className="getting-started-footer-row">
              <Col className="video-player-col">
                <ReactPlayer
                  className="ptb-player"
                  url="https://youtu.be/vzaXyIDilt0"
                  width={playerDimension.width}
                  height={playerDimension.height}
                  onStart={videoStarted}
                  onPause={videoPaused}
                  onPlay={videoStarted}
                  controls={true}
                  loop={true}
                  light={true}
                  config={{
                    youtube: {
                      playerVars: {
                        modestbranding: 0,
                        controls: 1,
                      },
                    },
                  }}
                />
              </Col>

              <Col className={videoTextClass}>
                <h3 className="video-player-title">PTB Challenge</h3>
                <div className="video-player-text">
                  This video demonstrates how SAIC deploys sound digital
                  engineering (DE) practices within each engineering domain and
                  creates executable and traceable digital threads across
                  multiple engineering tools and disciplines spanning the full
                  engineering lifecycle.
                </div>
                <a
                  className="ptb-download-link"
                  href="https://software-downloads.s3-us-gov-west-1.amazonaws.com/NIWC_Lant.mp4"
                >
                  <div className="ptb-fancy-button">Download Video</div>
                </a>
              </Col>
            </Row>
          </Row>
        </Container>
      ) : (
        <DemoLogin setAuthenticated={setAccess} />
      )}
      <DemoFooter />
    </>
  );
};

export default PTBPage;
