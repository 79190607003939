import React, { createContext, useState, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { CognitoAuth } from "amazon-cognito-auth-js";
// import * as AWS from "aws-sdk";
import Pool from "./UserPool";
import { getTable, checkNewUser, checkIP } from "../services/ReadyOneAPI";

var authData = {
  ClientId: "4am2prse8uqojha4hmpu4v7qe9", // Your client id here
  AppWebDomain: "readyone.net",
  TokenScopesArray: ["email", "openid", "aws.cognito.signin.user.admin"], // e.g.['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'],
  RedirectUriSignIn: "/",
  RedirectUriSignOut: "/",
  IdentityProvider: "saic", // e.g. 'Facebook',
  UserPoolId: "us-gov-west-1_ldWyPvzw9", // Your user pool id here
  AdvancedSecurityDataCollectionFlag: false, // e.g. true
};
var auth = new CognitoAuth(authData);

const AccountContext = createContext();

const Account = (props) => {
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [opshub, setOpshub] = useState([]);
  const [notification, setNotification] = useState([]);
  const [restrictedUsers, setRestrictedUsers] = useState([]);
  const [restricted, setRestricted] = useState(false);
  const [dashboard, setDashboard] = useState([]);
  const [newUser, setNewUser] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [internal, setInternal] = useState(false);

  useEffect(() => {
    checkIP().then((result) => {
      console.log(result);
      setInternal(result.internal);
    })
  }, [])

  useEffect(() => {
    checkNewUser(username).then((result) => {
      if (JSON.parse(result.body).isLessThanADay) {
        setNewUser(JSON.parse(result.body).isLessThanADay);
      }
    });
  }, [username]);

  useEffect(() => {
    if (email) {
      getUserLists();
      
      let user = email.split("@")[0];
      let count = user.split(".").length;
      if(count === 1) {
        setLastName("");
        setFirstName(user);
      } else if (count < 3) {
        setLastName(
          email.split(".")[1].charAt(0).toUpperCase() +
            email.split(".")[1].split("@")[0].slice(1)
        );
        setFirstName(
          email.split(".")[0].charAt(0).toUpperCase() +
            email.split(".")[0].slice(1)
        );
      } else {
        setLastName(
          email.split(".")[2].charAt(0).toUpperCase() +
            email.split(".")[2].split("@")[0].slice(1)
        );
        setFirstName(
          email.split(".")[0].charAt(0).toUpperCase() +
            email.split(".")[0].slice(1)
        );
      }
      
    }
  }, [email]);


  const getUserLists = () => {
    getTable("ReadyOne_UserLists").then((result) => {
      result.body.Items.map((i) => {
        const actions = {
          opshub: () => setOpshub(i.users),
          notification: () => setNotification(i.users),
          restricted: () => {
            setRestrictedUsers(i.users);
            checkEmail(i.users);
          },
          dashboard: () => setDashboard(i.users)
        };

        (actions[i.id] || (() => console.log("ID not recognized")))();
      });
    });
  };

  const checkEmail = (users) => {
    setRestricted(users.includes(email.toLowerCase()));
    setInitialized(true);
  };

  const getSession = async (accessToken) => {
    if (accessToken) {
      setToken(accessToken);
      var curUrl = window.location.href;

      auth.userhandler = {
        onSuccess: function (result) {
          //console.log("Sign in success");
          //console.log(result);
        },
        onFailure: function (err) {
          console.error("Error!");
        },
      };

      auth.parseCognitoWebResponse(curUrl);
      auth.getSession();
    }

    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      if (user) {
        user.getSession((err, session) => {
          if (err) {
            user.signOut();

            reject();
          }

          user.getUserAttributes((err, data) => {
            if (err) {
              user.signOut();

              reject();
            }
            console.log("Get User Attributes: ", data);
            data.map((attr) => {
              if (attr.Name === "email") setEmail(attr.Value.toLowerCase());
              if (attr.Name === "identities") {
                if (JSON.parse(attr.Value)[0]) {
                  if (JSON.parse(attr.Value)[0]["userId"]) {
                    console.log(JSON.parse(attr.Value)[0]);
                    setUsername(JSON.parse(attr.Value)[0]["userId"]);
                  }
                }
              }
            });
            setAuthenticated(true);
            resolve({ session: session, data: data });
          });
        });
      } else {
        reject();
      }
    });
  };

  const getUserName = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      if (user) {
        user.getUserAttributes((err, data) => {
          if (err) {
            //console.log(err);
            reject(err);
          }

          data.map((attr) => {
            if (attr.Name === "email") setEmail(attr.Value.toLowerCase());
            if (attr.Name === "identities") {
              if (JSON.parse(attr.Value)[0]) {
                if (JSON.parse(attr.Value)[0]["userId"]) {
                  resolve(JSON.parse(attr.Value)[0]["userId"]);
                }
              }
            }
          });
        });
      } else {
        reject();
      }
    });
  };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          //console.log("onSuccess: ", data);
          setAuthenticated(true);
          resolve(data);
        },
        onFailure: (err) => {
          //console.error("onFailure :", err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          //console.log("newPasswordRequired: ", data);
          resolve(data);
        },
      });
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    setAuthenticated(false);
  };

  const accessToken = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      console.log("getSession user data: ", user);
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          }
          resolve(session.getIdToken().getJwtToken());
        });
      } else {
        reject();
      }
    });
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        getUserName,
        accessToken,
        username,
        firstName,
        lastName,
        email,
        setAuthenticated,
        authenticated,
        token,
        opshub,
        notification,
        dashboard,
        restricted,
        newUser,
        initialized,
        internal
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
