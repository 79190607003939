import React from "react";
import styles from "./Button.module.css";

export default function Button({ onClick, title, image }) {
  return (
    <button className={styles.button} onClick={onClick}>
      <div className={styles.selector}>
        <img src={image} />
      </div>
      <span>{title}</span>
    </button>
  );
}
