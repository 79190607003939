import React, { useEffect, useState, useContext, useRef } from "react";
import { AccountContext } from "../../auth";
import { ToastContext } from "../../layout";
import styles from "./Dashboards.module.css";
import DashboardSelector from "./DashboardSelector/DashboardSelector";
import Dashboard from "./Dashboard/Dashboard";

const Dashboards = () => {
    const [dashboard, setDashboard] = useState({});
    const [show, setShow] = useState(true)

    const { notifyInfo } = useContext(ToastContext);

    useEffect(() => {
        console.log(dashboard)
        if(!isEmpty(dashboard)) {
            setShow(false)
            notifyInfo(`Now viewing ${dashboard.title}`)
        }
        
    }, [dashboard])

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

  return (
        <>
        <DashboardSelector show={show} close={() => setShow(false)} setDashboard={setDashboard} />
        {!show && <Dashboard title={dashboard.title} link={dashboard.report} update={() => setShow(true)}/>}
        </>
  );
};

export default Dashboards;
