import React from "react";
import "./PTBNavbar.css";
import { Container, Row } from "react-bootstrap";
import ImageHelper from "../../components/ImageHelper";

export const DemoFooter = (props) => {
  return (
    <>
      <div
        className="footer top-shadow d-none d-lg-block"
        expand="lg"
        sticky="bottom"
      >
        <Container fluid>
          <Row className="footer-bar-logo">
            <ImageHelper
              className="footer-bar-logo"
              mode="saiclogo"
              width="7rem"
              onClick={() => {}}
            />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DemoFooter;
