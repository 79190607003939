import React, { useState, useEffect } from "react";
import styles from "./DashboardSelector.module.css";
import Modal from "./Modal/Modal";
import { getTable } from "../../../services/ReadyOneAPI";

const DashboardSelector = ({ show, close, setDashboard }) => {
  const [dashboards, setDashboards] = useState([]);
  const [internalShow, setInternalShow] = useState(show);
  useEffect(() => {
    getTable("ReadyOne_Dashboards").then((result) => {
      setDashboards(result.body.Items);
    });
  }, []);

  useEffect(() => {
    show
      ? setInternalShow(show)
      : setTimeout(() => {
          setInternalShow(show);
        }, 600);
  }, [show]);

  const handleChange = (event) => {
    setDashboard(dashboards[event.target.value]);
  };

  return (
    <Modal
      show={internalShow}
      title={"Select a Dashboard"}
      onClick={() => {
        close();
      }}
      hideModal={close}
      className={show ? styles.puffIn : styles.puffOut}
    >
      <select className={styles.dropdown} onChange={handleChange}>
        <option value="">Select an option</option>
        {dashboards.map((dash, idx) => (
          <option value={idx}>{dash.title}</option>
        ))}
      </select>
    </Modal>
  );
};

export default DashboardSelector;
