import React from "react";
import styles from "./Modal.module.css";

export default function Modal({ id, show, title, children, buttonText, onClick, hideModal, className }) {
  return (
    <div id={id} className={`${styles.modal} ${show ? styles.show : styles.hide} ${className}`}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <h2>{title}</h2>
        </div>
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          {buttonText && <button onClick={onClick}>{buttonText}</button>}
          <button onClick={hideModal}>Cancel</button>
        </div>
      </div>
    </div>
  );
}
