import React from "react";
import styles from "./Footer.module.css";

export default function Footer({ children, title, body }) {
  return (
    <div className={styles.footerBox}>
      <div className={styles.player}>{children}</div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.body}>{body}</div>
      </div>
    </div>
  );
}
