import React from "react";
import styles from "./Title.module.css";

export default function Title({ title, subtitle, image }) {
  return (
    <div className={styles.container}>
      {image && <img src={image} className={styles.image} alt="title box" />}
      <h1 className={styles.title}>{title}</h1>
      <h2 className={styles.subtitle}>{subtitle}</h2>
    </div>
  );
}
