import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import {getUserRoles} from "../../../services/TeamworkCloudAdminAPI";
import "../modals.css";

const TWCMyRoles = (props) => {
  const [roles, setRoles] = useState([]);


  useEffect(() => {
    
     getUserRoles(props.username).then((result) => {
         setRoles(result.body)
     })
    
  }, []);

  return (
    <Modal show={props.show} onHide={() => props.secondButton(false)} centered>
      <Modal.Header className="confirm-modal-header">
        <Modal.Title className={props.mode}>
          My Teamwork Cloud Permissions
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="confirm-modal-body">
        {
            roles && roles.map(role => (
                <>
                <h4 className="login-text-white">{role.roleName}</h4>
                <ul>
                {role.projects.map(proj => (
                    <li className="login-text-white">{proj.projectName}</li>
                ))}
                </ul>
                </>
            ))
        }
      </Modal.Body>

      <Modal.Footer className="confirm-modal-footer">
        <Button
          variant="danger"
          className="modal-button"
          onClick={() => props.secondButton(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TWCMyRoles;
