import React, { useState, useEffect, useContext } from "react";
import { getTable } from "../../../../services/ReadyOneAPI";
import RingLoader from "react-spinners/RingLoader";
import styles from "./AppDrawer.module.css";
import { Header, AppStreamButton } from "../AppComponents";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { AccountContext } from "../../../../auth";

const AppDrawer = ({ toggleAllApps, toggleMyApps }) => {
  const [displayApps, setDisplayApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const { email, restricted, initialized } = useContext(AccountContext);

  useEffect(() => {
    setLoading(true);
  }, [])

  useEffect(() => {
    if (initialized) {
      getTable("AppStreams").then((result) => {
        console.log(result.body.Items);
        let apps = result.body.Items.sort(compare).filter(hidden);
        if (restricted) apps = apps.filter(restrict);
        apps.push({
          "appName": "Dashboards",
          "appLink": "https://readyone.net/Dashboards",
          "category": "addon",
          "image": "https://s3-us-gov-west-1.amazonaws.com/resources.readyone.net/site_images/app_streams/ibm.png",
          "mode": "elm",
          "title": "Dashboards"
         })
         console.log(apps)
        setDisplayApps(apps);
        setLoading(false);
      });
    }
  }, [initialized]);

  const compare = (a, b) => {
    return b.category === "core" ? 1 : -1;
  };

  const hidden = (app) => {
    if(app.restricted  && restricted) return true; 
    if(app.users && app.users.includes(email)) return true;
    return !app.hide;
  };

  const restrict = (app) => {
    return app.restricted;
  };

  return (
    <div className={styles.appHolder}>
      {loading ? (
        <RingLoader size={80} color={"#ffffff"} loading={loading} />
      ) : (
        <>
          <Header title="Core Apps">
            {!restricted && (
              <BsFillGrid3X3GapFill
                className={styles.gridApps}
                onClick={() => toggleMyApps()}
              />
            )}
          </Header>

          <CoreApps applications={displayApps} />

          {!restricted && (
            <button
              className={styles.appDrawerButton}
              onClick={() => toggleAllApps()}
            >{`Show All Apps`}</button>
          )}
        </>
      )}
    </div>
  );
};

const CoreApps = ({ applications }) => {
  return (
    <div className={styles.core}>
      {applications
        .filter((app) => app.category === "core")
        .map((app) => (
          <AppStreamButton
            mode={app.mode}
            key={`${app.appName} ${app.title} ${app.mode} Main Column`}
            application={app}
            spinClass="spin"
          />
        ))}
    </div>
  );
};

export default AppDrawer;
