import React, { useState } from "react";
import styles from "./FileUpload.module.css";
import Modal from "./Modal/Modal";
import { uploadFile } from "../../services/ReadyOneAPI";

const FileUpload = ({ show, close }) => {
  // fileToUpload contains the actual file object
  // uploadSuccess becomes true when the file upload is complete
  const [fileToUpload, setFileToUpload] = useState(undefined);
  const [uploadSuccess, setUploadSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [uploading, setUploading] = useState(false);


  const upload = () => {
    setUploading(true);
    uploadFile(fileToUpload, fileToUpload.name).then((result) => {
      console.log(result);
      setUploading(false);
      setUploadSuccess(result.uploadSuccess);
      setError(result.error);
    });
  };

  return (
    <Modal
      id="notification-modal"
      show={true}
      title={"Upload File"}
      onClick={() => {
        upload();
      }}
      hideModal={() => {}}
    >
      <div className={styles.editContainer}>
        
          <div className={styles.selectContainer}>
            <form className={styles.fileSelectCont}>
             
                {/* <label for="fileUpload" className={styles.fileButton} /> */}
                <input
                  type="file"
                  className={styles.fileButtonText}
                  id="fileUpload"
                  onChange={(e) => {
                    setFileToUpload(e.target.files[0]);
                  }}
                />

                {fileToUpload ? (
                  <button
                    type="button"
                    className={styles.uploadButton}
                    onClick={(e) => {
                      upload();
                    }}
                  >
                    Upload your file
                  </button>
                ) : null}
                <div>
                <span>{uploading ? "Upload in progress" : ""}</span>
                  <span>{uploadSuccess ? "File Upload Successfully" : ""}</span>
                </div>
              
            </form>
          </div>
        </div>
    </Modal>
  );
};

export default FileUpload;
