import { Folder, SearchItem, FolderItem } from "./Files/";
import styles from "./Content.module.css";

export default function Content({
  searchValue,
  searchResults,
  data,
  activeMenu,
  setActiveMenu,
}) {
  return (
    <div className={styles.content}>
      {searchValue === "" ? (
        data.map((folder) => (
          <>
            <div
              className={`${styles.explorer} ${
                activeMenu === "main" ? styles.enter : styles.exit
              }`}
            >
              <FolderItem file={folder} setActiveMenu={setActiveMenu} />
            </div>

            <Folder
              folder={folder}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
            />
          </>
        ))
      ) : (
        <>
          {searchResults.map((result) => (
            <SearchItem file={result} />
          ))}
        </>
      )}
    </div>
  );
}
