import React, { useState, useEffect, useContext } from 'react';
import styles from './UserLists.module.css';
import Modal from "../Modal/Modal";
import { getTable, removeUserFromList, addUserFromList } from "../../../../services/ReadyOneAPI";
import { ToastContext } from "../../../../layout";


const UserLists = ({ show, close }) => {

  const [selectedOption, setSelectedOption] = useState('notification');
  const [newUser, setNewUser] = useState('');
  const [opshubUsers, setOpshubUsers] = useState([]);
  const [notificationUsers, setNotificationUsers] = useState([]);
  const [dashboardUsers, setDashboardUsers] = useState([]);
  const [restrictedUsers, setRestrictedUsers] = useState([]);
  const { notifySuccess, notifyError } = useContext(ToastContext);

  useEffect(() => {
    updateUsers();
  }, [])

  const updateUsers = () => {
    getTable("ReadyOne_UserLists").then(result => {
      result.body.Items.map(i => {
        const actions = {
          'opshub': () => setOpshubUsers(i.users),
          'notification': () => setNotificationUsers(i.users),
          'restricted': () => setRestrictedUsers(i.users),
          'dashboard': () => setDashboardUsers(i.users)
       };
      
      (actions[i.id] || (() => console.log('ID not recognized')))();
      })
    })
  }

  const handleChange = event => setSelectedOption(event.target.value);

  const handleInputChange = event => setNewUser(event.target.value);

  const handleAdd = () => {
    addUserFromList(selectedOption, newUser).then((result) => {
      notifySuccess(result.replace(/^"|"$/g, ""));
      updateUsers();
    })
    
    setNewUser('');
  };

  const handleDelete = user => {
    removeUserFromList(selectedOption, user).then((result) => {
      notifySuccess(result.replace(/^"|"$/g, ""));
      updateUsers();
    })
  };

  let users;
  switch (selectedOption) {
    case 'notification':
      users = notificationUsers;
      break;
    case 'restricted':
      users = restrictedUsers;
      break;
    case 'opshub':
      users = opshubUsers;
      break;
      case 'dashboard':
        users = dashboardUsers;
        break;
    default:
      users = [];
  }

  return (
    <Modal
    id="notification-modal"
    show={show}
    title={"User Lists"}
    onClick={() => {
      close();
    }}
    hideModal={close}
  >
    <div className={styles.container}>
      <select value={selectedOption} onChange={handleChange} className={styles.dropdown}>
        <option value="notification">Notification</option>
        <option value="restricted">Restricted</option>
        <option value="opshub">OpsHub</option>
        <option value="dashboard">Dashboard Admins</option>
      </select>

      <input
        type="text"
        value={newUser}
        onChange={handleInputChange}
        className={styles.input}
        placeholder="Add user"
      />

      <button onClick={handleAdd} className={styles.button}>
        Add
      </button>

      <ul className={styles.userList}>
        {users.map(user => (
          <li key={user} className={styles.user}>
            {user}
            <button onClick={() => handleDelete(user)} className={styles.deleteButton}>
              X
            </button>
          </li>
        ))}
      </ul>
    </div>
    </Modal>
  );
};

export default UserLists;
