import React from "react";
import "./DemoNavbar.css";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageHelper from "../../components/ImageHelper";

const DemoNavbar = (props) => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="navbar bottom-shadow"
        sticky="top"
      >
        <Navbar.Brand className="brand">
          <ImageHelper
            mode="newRO"
            width="5.5em"
            className="navbarbrand"
            onClick={() => {}}
          />
          <div className="d-inline" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-none" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            fill
            variant="tabs"
            className="mr-auto d-none d-lg-flex"
            defaultActiveKey="/Getting-Started"
            // activeKey={getActiveKey()}
          >
            <Nav.Link
              eventKey="/Getting-Started"
              as={Link}
              className="white-nav-text"
              to="/Demo"
            >
              <h5 className="demoTab"> Demo </h5>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default DemoNavbar;
