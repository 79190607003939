import React from "react";
import {
  CloseButton,
  PageTitle,
  AppStreamButton,
  styles,
} from "../AppComponents";

export default function AllApps({ applications, show, stopShow }) {
  return (
    <div class={`${styles.modal} ${!show && styles.hidden}`}>
      <div class={`${styles.modalContent} ${!show && styles.hidden}`}>
        <PageTitle title="All Apps" onClick={() => {}} hide={true} />
        <CloseButton close={stopShow} />

        {applications.map((app) => (
          <div
            key={`${app.appName} ${app.title} ${app.mode} Main Column`}
            className={styles.appStreamButtonContainer}
          >
            <AppStreamButton
              className={styles.itemInMain}
              mode={app.mode}
              application={app}
              key={`${app.appName} ${app.title} ${app.mode} Main Button`}
              spinClass="spin"
              onClick={() => {}}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
