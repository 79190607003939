import React, { useState, useContext } from "react";
import "./RequestForm.css";
import RequestButton from "../../components/RequestButton";
import NewUserModal from "../../components/Modals/NewUser/NewUserModal";
import TWCModal from "../../components/Modals/TeamworkCloud/TWCModal";
import ELMModal from "../../components/Modals/IBM/ELMModal";
import InnovatorModal from "../../components/Modals/Innovator/InnovatorModal";
import { AccountContext } from "../../auth";
import { Title, Content } from "./Layout";
import { Button, Modal, Menu } from "./Components";

const RequestForm = (props) => {
  const [activeModal, setActiveModal] = useState(null);
  const options = [
    {
      title: "New User",
      image:
        "https://s3-us-gov-west-1.amazonaws.com/resources.readyone.net/site_images/app_streams/newuser.png",
    },
    {
      title: "Teamwork Cloud",
      image:
        "https://s3-us-gov-west-1.amazonaws.com/resources.readyone.net/site_images/app_streams/TeamworkCloudLogo.png",
    },
    {
      title: "IBM ELM",
      image:
        "https://s3-us-gov-west-1.amazonaws.com/resources.readyone.net/site_images/app_streams/ibm.png",
    },
    {
      title: "Aras Innovator",
      image:
        "https://s3-us-gov-west-1.amazonaws.com/resources.readyone.net/site_images/app_streams/aras.png",
    },
  ];
  const { email, username } = useContext(AccountContext);

  return (
    <div>
      <Title title="Request Form" subtitle="Select a form to get started" />
      <Content>
        {options.map((option) => (
          <Button
            onClick={() => {
              setActiveModal(option.title);
            }}
            title={option.title}
            image={option.image}
          />
        ))}
      </Content>

      <NewUserModal
        show={activeModal === "New User"}
        secondButton={() => setActiveModal(null)}
        mode="confirm"
        requesterEmail={email}
      />
      <TWCModal
        show={activeModal === "Teamwork Cloud"}
        secondButton={() => setActiveModal(null)}
        mode="confirm"
        username={username}
        requesterEmail={email}
      />
      <ELMModal
        show={activeModal === "IBM ELM"}
        secondButton={() => setActiveModal(null)}
        mode="confirm"
        requesterEmail={email}
      />
      <InnovatorModal
        show={activeModal === "Aras Innovator"}
        secondButton={() => setActiveModal(null)}
        mode="confirm"
        requesterEmail={email}
      />
    </div>
  );
};

export default RequestForm;
