import React, { useState, useContext, useEffect, Component } from "react";
import "./Login.css";
import styles from "./Login.module.css";
import { Button, Form, Col, Modal, Row } from "react-bootstrap";
import { AccountContext } from "./Account";
import { useLocation, Navigate } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import ImageHelper from "../components/ImageHelper";
import GoodModal from "./Modal/Modal";
import { sendEmail } from "../services/ReadyOneAPI";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { authenticate, getSession } = useContext(AccountContext);
  const search = useLocation().hash;

  const accessToken = new URLSearchParams(search).get("#access_token") || "";
  const errorDescription =
    new URLSearchParams(search).get("#error_description") || "";
  useEffect(() => {
    setLoading(true);

    getSession(accessToken)
      .then((result) => {
        console.log("FRom Loging user effect:", result.data);
      })
      .catch(() => {
        setLoading(false);
      });
    if (errorDescription) setIsError(true);
  }, []);

  const submitRequest = () => {
    if (!email) {
      setInvalidEmail(true);
      return;
    } else {
      setInvalidEmail(false);
      setSubmitting(true);
      sendEmail({
        requestTemplate: "new-user",
        email,
        shortformUsername: email,
        requesterEmail: email,
      }).then((result) => {
        setSubmitting(false);
        setSubmitted(true);
      });
    }
  };

  const openLogin = () => {
    console.log(window.location.hostname);
    switch (window.location.hostname) {
      case "dev.readyone.net":
        window.location.href =
          "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://dev.readyone.net/";
        break;
      case "readyone.net":
        window.location.href =
          "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://readyone.net/";
        break;
      case "localhost":
        window.location.href =
          "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=http://localhost:3000/";
        break;
      default:
        window.location.href =
          "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://readyone.net/";
        break;
    }
  };

  return loading ? (
    <div className={styles.loginRingLoader}>
      <RingLoader size={80} color={"#ffffff"} loading={loading} />
    </div>
  ) : isAuth ? (
    <Navigate to="/Getting-Started" />
  ) : !isError ? (
    <div className={styles.loginWholePage}>
      {/* style={{ backdropFilter: blur + 4 }} */}
      <div className={styles.loginModal} show={true} centered>
        <div className={styles.loginModalLogo}>
          <ImageHelper mode="saiclogo" width="35%" onClick={() => {}} />
        </div>

        <div className={styles.loginModalRo}>
          <div className={styles.brandLogo}>
            ReadyOne<div className={styles.trademark}>TM</div>
          </div>
        </div>

        <div className={styles.loginModalHeader}>
          <div>
            <div className={styles.loginMessage}>Please login to continue.</div>
          </div>
        </div>

        <div className={styles.loginModalFooter}>
          <Button
            variant="danger"
            className={styles.loginModalButton}
            onClick={() => {
              openLogin();
            }}
          >
            SAIC SSO Login
          </Button>
        </div>

        <div className={styles.loginModalFooterDisc}>
          <Button className={styles.discButton} onClick={() => setShow(!show)}>
            <div className={styles.loginDiscText} id="disclaimer">
              By accessing this site, you are agreeing to SAIC's Acceptable Use
              Policy.
              <br />
              Click to learn more.
              {show ? (
                <div className={styles.policyButton}>
                  This computer system is the property of SAIC and includes all
                  related equipment, networks, and network devices. This
                  computer system may contain US government information and is
                  only for use by authorized users for business purposes. There
                  is no right of privacy in this computer system. Use
                  constitutes consent and acknowledgement that the computer
                  system, and any information placed or sent over the computer
                  system, is monitored, recorded and subject to audit and
                  collection for all lawful purposes including in relation to
                  investigations and/or legal proceedings. Any unauthorized use,
                  accessing of or attempt to access this computer system, or any
                  unauthorized alteration, damage, removing, addition, or
                  destruction of any data, program or software in this computer
                  system is subject to criminal and civil prosecution under
                  state and/or federal law. Unauthorized use may result in the
                  delivery of possible evidence collected during monitoring to
                  law enforcement officials. Failure to comply with this notice
                  may be grounds for disciplinary action, up to and including
                  termination and/or civil and criminal prosecution.
                  <br />
                </div>
              ) : null}
            </div>
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <GoodModal
      show={isError}
      title={"Unauthorized"}
      onClick={() => {
        submitRequest();
      }}
      hideModal={() => setIsError(false)}
      submitted={submitted}
      invalidEmail={invalidEmail}
    >
      {submitting ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RingLoader size={80} color={"#ffffff"} loading={submitting} />
        </div>
      ) : submitted ? (
        <>
          <h4>Access Requested!</h4>
          <p>Access to ReadyOne has been requested for {email}</p>
          <p>
            A ReadyOne Administrator will reach out when your account has been
            added.
          </p>
        </>
      ) : (
        <div>
          <h4>You are not in the ReadyOne Access Group</h4>
          <p>To request access, enter your email address below.</p>
          <label>
            SAIC Email address:
            <input name="email" placeholder="Email Address"onChange={(e) => setEmail(e.target.value)} />
          </label>
          <p>
            A ReadyOne Administrator will reach out when your account has been
            added.
            
          </p>
          

          {invalidEmail && <span>You must enter an email address</span>}
        </div>
      )}
    </GoodModal>
  );
}
