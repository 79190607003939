import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import ELMUserPermissions from "./ELMUserPermissions";
import ELMCreateProject from "./ELMCreateProject"
import "../modals.css";

const ELMModal = (props) => {
  const [showUserPermission, setShowUserPermission] = useState(false);
  const [showCreateProject, setShowCreateProject] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.secondButton(false)}
        centered
      >
        <Modal.Header className="confirm-modal-header">
          <Modal.Title className={props.mode}>
            IBM ELM
          </Modal.Title>
          {/* <CloseButton /> */}
        </Modal.Header>

        <Modal.Body className="confirm-modal-body">
          <br />
          <Button
            variant="primary"
            className="modal-button-body"
            onClick={() => {
              setShowUserPermission(true);
              props.secondButton(false);
            }}
          >
            Add/Update User Permissions
          </Button>

          <br />
          <br />

          <Button
            variant="primary"
            className="modal-button-body"
            onClick={() => {
              setShowCreateProject(true);
              props.secondButton(false);
            }}
          >
            Create Project
          </Button>

          <br />
          {/* <br />
          <Button
            variant="primary"
            className="modal-button-body"
            onClick={() => props.firstButton()}
          >
            Ex.
          </Button>
          <br />
          <br />
          <Button
            variant="primary"
            className="modal-button-body"
            onClick={() => props.firstButton()}
          >
            Ex.
          </Button>
          <br /> */}
          <br />
        </Modal.Body>

        <Modal.Footer className="confirm-modal-footer">
          <Button
            variant="danger"
            className="modal-button"
            onClick={() => props.secondButton(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ELMUserPermissions
        show={showUserPermission}
        secondButton={setShowUserPermission}
        mode="confirm"
        requesterEmail={props.requesterEmail}
      />
      <ELMCreateProject
        show={showCreateProject}
        secondButton={setShowCreateProject}
        mode="confirm"
        requesterEmail={props.requesterEmail}
      />
    </>
  );
};

export default ELMModal;