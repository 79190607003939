import React from "react";
import ReactPlayer from "react-player";
import styles from "./Video.module.css";

export default function Video({ url, onStart, onPause, onPlay }) {
  return (
    <div className={styles.playerWrapper}>
      <ReactPlayer
        className={styles.reactPlayer}
        url={url}
        width="100%"
        height="100%"
        controls={true}
        loop={true}
        light="https://s3-us-gov-west-1.amazonaws.com/resources.readyone.net/site_images/thumbnails/welcome-video.png"
        onStart={onStart}
        onPause={onPause}
        onPlay={onPlay}
        config={{
          youtube: {
            playerVars: {
              modestbranding: 0,
              controls: 1,
            },
          },
        }}
      />
    </div>
  );
}
