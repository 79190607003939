import copyImg from "../images/copy.png";
import refreshImg from "../images/refresh.png";
import deleteImg from "../images/delete.png";
import restartImg from "../images/restart.png";
import saicImg from "../images/saic.png";
import saiccopyImg from "../images/saiccopy.png";
import teamworkcloudlogoImg from "../images/TeamworkCloudLogo.png";
import newuserImg from "../images/newuser.png";
import ibmImg from "../images/ibm.png";
import downloadImg from "../images/download.png";
import saiclogoImg from "../images/saiclogo.png";
import arasImg from "../images/aras.png";
import ewmImg from "../images/ewm.png";
import rhapsodyImg from "../images/rhapsody.png";
import solidworksImg from "../images/solidworks.png";
import cameosp3Img from "../images/sp3.png";
import cameosp4Img from "../images/sp4.png";
import eifstackImg from "../images/eifstack.png";
import eiflongImg from "../images/eiflong.png";
import readyoneImg from "../images/readyone.png";
import deafultappImg from "../images/defaultapp.png";
import modelCenterImg from "../images/modelcenter.png";
import tomsawyerImg from "../images/tomsawyer.png";
import stkImg from "../images/stk.png";
import powerpointImg from "../images/powerpoint.png";
import excelImg from "../images/excel.png";
import wordImg from "../images/word.png";
import toolfedteamImg from "../images/toolfedteam.png";
import deecoteamImg from "../images/deecoteam.png";
import dareteamImg from "../images/dareteam.png";
import newROlogoImg from "../images/newROlogo.png";
import cameotrainImg from "../images/cameotrain.png";
import doorsImg from "../images/doors.png";
import React from "react";

const iconObj = {
  copy: copyImg,
  refresh: refreshImg,
  delete: deleteImg,
  restart: restartImg,
  saic: saicImg,
  mock: saiccopyImg,
  twclogo: teamworkcloudlogoImg,
  newuser: newuserImg,
  ibm: ibmImg,
  download: downloadImg,
  saiclogo: saiclogoImg,
  aras: arasImg,
  ewm: ewmImg,
  rhapsody: rhapsodyImg,
  solidworks: solidworksImg,
  cameosp3: cameosp3Img,
  cameosp4: cameosp4Img,
  eif: eifstackImg,
  eiflong: eiflongImg,
  readyone: readyoneImg,
  defaultapp: deafultappImg,
  modelcenter: modelCenterImg,
  tomsawyer: tomsawyerImg,
  elm: ibmImg,
  agistk: stkImg,
  word: wordImg,
  excel: excelImg,
  powerpoint: powerpointImg,
  ToolFederation: toolfedteamImg,
  DARE: dareteamImg,
  DEecosystem: deecoteamImg,
  newRO: newROlogoImg,
  cameoTrain: cameotrainImg,
  doors: doorsImg,
};

class ImageHelper extends React.Component {
  render() {
    return (
      <img
        style={{
          width: this.props.width,
          color: this.props.color,
          justifyContent: "center",
        }}
        alt={this.props.mode}
        src={iconObj[this.props.mode] || iconObj["defaultapp"]}
        className={this.props.className}
        onClick={() => this.props.onClick()}
      />
    );
  }
}

ImageHelper.defaultProps = {
  width: "1.5rem",
  color: "gainsboro",
};

export default ImageHelper;
