import React, { useState, useEffect, useContext } from "react";
import {
  CloseButton,
  PageTitle,
  AppStreamButton,
  AppStreamDeleteButton,
  styles,
} from "../AppComponents";
import { updateUserApps } from "../../../../services/ReadyOneAPI";
import { AccountContext } from "../../../../auth";

const MyApps = ({ applications, myApps, show, stopShow, setMyApps }) => {
  const [edit, setEdit] = useState(false);
  const [key, setKey] = useState(0);
  const [loading, setLoading] = useState(false);

  const { username } = useContext(AccountContext);
  useEffect(() => {
    setEdit(false);
  }, [show]);

  const removeApp = (appName) => {
    console.log(`Removing ${appName}`);
    let temp = myApps.filter((app) => app !== appName);

    setMyApps(temp);
    setLoading(true);
    updateUserApps(`saic_${username}`, temp).then((result) => {
      console.log(result);
      setLoading(false);
    });
    console.log(temp);
  };

  const addApp = (appName) => {
    console.log(`Adding ${appName}`);
    let temp = myApps;
    temp.push(appName);
    setMyApps(myApps.concat(appName));
    console.log(myApps);
    setLoading(true);
    updateUserApps(`saic_${username}`, temp).then((result) => {
      console.log(result);
      setLoading(false);
    });
  };

  return (
    <div
      class={`${styles.modal} ${!show && styles.hidden} ${edit && styles.edit}`}
    >
      <div
        class={`${styles.modalContent} ${!show && styles.hidden} ${
          edit && styles.shrink + " " + styles.jiggle
        }`}
        key={key}
      >
        <PageTitle
          title="My Apps"
          onClick={() => setEdit(!edit)}
          hide={edit}
          edit={edit}
        />
        {!edit && <CloseButton close={stopShow} />}

        {applications
          .filter((app) => myApps.includes(app.appName))
          .map((app) => (
            <div
              key={`${app.appName} ${app.title} ${app.mode} Main Column`}
              className={styles.appStreamButtonContainer}
            >
              <AppStreamDeleteButton
                hidden={!edit}
                add={false}
                onClick={() => removeApp(app.appName)}
              />
              <AppStreamButton
                className={styles.itemInMain}
                mode={app.mode}
                // width="10rem"
                application={app}
                key={`${app.appName} ${app.title} ${app.mode} Main Button`}
                spinClass="spin"
                disabled={edit}
                onClick={() => removeApp(app.appName)}
              />
            </div>
          ))}
      </div>
      {edit && (
        <div
          class={`${styles.modalContent} ${
            edit && styles.shrink + " " + styles.jiggle
          }`}
        >
          <PageTitle
            title="All Apps"
            onClick={() => setEdit(!edit)}
            loading={loading}
            edit={edit}
          />

          {applications
            .sort((a, b) => {
              if (myApps.includes(a.appName) && !myApps.includes(b.appName)) {
                return 1;
              } else if (
                !myApps.includes(a.appName) &&
                myApps.includes(b.appName)
              ) {
                return -1;
              } else {
                return 0;
              }
            })
            .map((app) => (
              <div
                key={`${app.appName} ${app.title} ${app.mode} Main Column`}
                className={styles.appStreamButtonContainer}
              >
                <AppStreamDeleteButton
                  hidden={!edit}
                  add={true}
                  isMyApp={myApps.includes(app.appName)}
                  onClick={() => addApp(app.appName)}
                />
                <AppStreamButton
                  className={styles.itemInMain}
                  mode={app.mode}
                  // width="10rem"
                  application={app}
                  key={`${app.appName} ${app.title} ${app.mode} Main Button`}
                  spinClass="spin"
                  disabled={true}
                  onClick={() => addApp(app.appName)}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MyApps;
