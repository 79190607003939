import BottomLinks from "../BottomLinks.json";
import { BsLink45Deg } from "react-icons/bs";
import { getRootFolder } from "../Content";
import defaultapp from "../../../images/defaultapp.png";
import styles from "./Footer.module.css";

export default function Footer({ activeMenu }) {
  return (
    <div className={styles.footer}>
      {BottomLinks.map(
        (link) =>
          link.resourceName === getRootFolder(activeMenu) &&
          link.links.map((l) => (
            <button
              className={styles.pillButton}
              onClick={() => window.open(l.value)}
            >
              {l.type === "link" ? (
                <BsLink45Deg size="2.5rem" />
              ) : (
                <img src={defaultapp} />
              )}
              <span>{l.name}</span>
            </button>
          ))
      )}
    </div>
  );
}
