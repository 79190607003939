export const getRootFolder = (activeMenu) => {
  return activeMenu.split("/")[1] || "main";
};

export const getFileNameFromKey = (key) => {
  let split = key.split("/");

  if (isDirectory(key)) {
    return split[split.length - 2];
  } else {
    return split[split.length - 1];
  }
};

export const isDirectory = (fileKey) => {
  return fileKey.charAt(fileKey.length - 1) === "/";
};
