import React, { useState, useContext, useEffect } from "react";
import styles from "./DemoPage.module.css";
import DemoNavbar from "./DemoNavbar";
import { Container, Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";

const DemoPage = (props) => {
  const videos = [
    {
      title: "Getting Started with ReadyOne",
      link: "https://twc-data.s3-us-gov-west-1.amazonaws.com/ReadyOne+Demo.mp4",
      description:
        "In this video, you will find some useful information on getting started with App Streams. This video demonstrates launching engineering applications within your web browser, and navigating the App Streams toolbar.",
      class: "video-player",
    },
    {
      title: "Getting Started with ReadyTwo",
      link: "https://twc-data.s3-us-gov-west-1.amazonaws.com/ReadyOne+Demo.mp4",
      description:
        "In this video, you will find some useful information on getting started with App Streams. This video demonstrates launching engineering applications within your web browser, and navigating the App Streams toolbar.",
      class: "ptb-player",
    },
  ];

  return (
    <>
      <DemoNavbar />
      <Container className="getting-started-whole-box">
        <Row className="getting-started-title">
          Welcome To ReadyOne!
          <div className={styles.gettingStartedTitleText}>
            Select a video to view a demo
          </div>
        </Row>

        <Row className="getting-started-footer">
          {videos.map((video) => (
            <Row className="getting-started-footer-row">
              <Col className="video-player-col">
                <ReactPlayer
                  className={video.class}
                  url={video.link}
                  width="40rem"
                  height="25rem"
                  controls={true}
                  loop={true}
                  light={true}
                  config={{
                    youtube: {
                      playerVars: {
                        modestbranding: 0,
                        controls: 1,
                      },
                    },
                  }}
                />
              </Col>

              <Col className="video-player-text">
                <h3 className="video-player-title">{video.title}</h3>
                <div className="video-player-text">{video.description}</div>
              </Col>
            </Row>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default DemoPage;
