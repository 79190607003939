import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { css } from "@emotion/react";

import {
  createCategory,
  getCategories,
} from "../../../services/TeamworkCloudAdminAPI";
import {sendEmail} from "../../../services/ReadyOneAPI"
import Select from "react-select";
import RingLoader from "react-spinners/RingLoader";
import "../modals.css";

const TWCCreateCategory = (props) => {
  const [categoryName, setCategoryName] = useState("");
  const [resourceManagers, setResourceManagers] = useState([]);
  const [resourceContributors, setResourceContributors] = useState([]);
  const [resourceReviewers, setResourceReviewers] = useState([]);
  const [requesterEmail, setRequesterEmail] = useState(props.requesterEmail);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [instances, setInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getCategories().then((response) => {
      console.log(response);
      setInstances(response.instances)
    });
  }, []);

  useEffect(() => {
    if(selectedInstance){
      getCategories(selectedInstance.value).then((response) => {
        console.log(response);
        setUsers(response.users);
        setProjects(response.projects);
        //setInstances(response.instances)
      });
    }
    
  }, [selectedInstance])

  const createProject = async () => {
    setLoading(true);
    let payload = {
        requestTemplate: 'twc-create-category',
        username: props.username,
        instance: selectedInstance.value,
        projectName: categoryName,
        managers: resourceManagers.map((r) => r.value),
        contributors: resourceContributors.map((r) => r.value),
        reviewers: resourceReviewers.map((r) => r.value),
        requesterEmail
    }
    console.log(selectedInstance);
    await createCategory(payload).then((result) => {
      console.log(result);
      
      if(result.statusCode === 200) {
        setMessage(getSuccessTemplate(categoryName, result.body));
        setLoading(false);
        setDone(true);
      }else if(result.statusCode === 202){
        sendEmail(payload).then((res) => {
          setMessage(getRequestedTemplate(categoryName, res.body));
          setLoading(false);
          setDone(true);
        })
      }else{
        setMessage(getErrorTemplate(categoryName))
        setLoading(false);
        setDone(true);
      }
      
    });
  };

  const getSuccessTemplate = (name, link) => {
    return <div className="result-text">
      <div className="result-success">Success!</div>
      <div className="result-primary">Category <a>{name}</a> has been successfully created</div>
      <div className="result-secondary">Click <a href={link} target="_blank">here</a> to view it in the webapp</div>
    </div>
  }

  const getRequestedTemplate = (name, body) => {
    return <div className="result-text">
      <div className="result-requested">Requested</div>
      <div className="result-primary">Category <a>{name}</a> has been requested</div>
      <div className="result-secondary">{body}</div>
    </div>
  }

  const getErrorTemplate = (name) => {
    return <div className="result-text">
      <div className="result-error">Error</div>
      <div className="result-primary">There was a problem creating category <a>{name}</a></div>
      <div className="result-secondary">Please verify that category {name} does not already exist. Contact an administrator if the problem persists.</div>
    </div>
  }

  const override = css`
  display: block;
  margin: auto;
  margin-top: 40%;
`;

  return (
    <Modal show={props.show} onHide={() => props.secondButton(false)} centered>
      <Modal.Header className="confirm-modal-header">
        <Modal.Title className={props.mode}>Create Category</Modal.Title>
      </Modal.Header>

      <Modal.Body className="confirm-modal-body form">
        {done ? (message) : loading && !done ? (
          <RingLoader
            size={80}
            color={"#ffffff"}
            loading={loading}
            size="100px"
            css={override}
          />
        ) : (
          <>
          <Form.Group as={Col} >
              <Form.Label className="text-white">Teamwork Cloud Instance</Form.Label>
              <Select
                options={instances}
                isMulti={false}
                onChange={(event) => {
                  setSelectedInstance(event);
                }}
              />
            </Form.Group>
            <br />
            <Form.Group as={Col}>
              <Form.Label className="text-white">Category Name</Form.Label>
              <Form.Control
                onChange={(e) => setCategoryName(e.target.value)}
                as="input"
                className="my-1 mr-sm-2"
                id="inlineFormCustomSelectPref"
              ></Form.Control>
            </Form.Group>
            <br />
            <Form.Group as={Col} >
              <Form.Label className="text-white">Resource Managers</Form.Label>
              <Select
                options={users}
                isDisabled={users.length === 0}
                isMulti={true}
                onChange={(event) => {
                  setResourceManagers(event);
                }}
              />
            </Form.Group>
            <br />
            <Form.Group as={Col} >
              <Form.Label className="text-white">
                Resource Contributors
              </Form.Label>
              <Select
                options={users}
                isDisabled={users.length === 0}
                isMulti={true}
                onChange={(event) => {
                  setResourceContributors(event);
                }}
              />
            </Form.Group>
            <br />
            <Form.Group as={Col} >
              <Form.Label className="text-white">Resource Reviewers</Form.Label>
              <Select
                options={users}
                isDisabled={users.length === 0}
                isMulti={true}
                onChange={(event) => {
                  setResourceReviewers(event);
                }}
              />
            </Form.Group>
          </>
        )}
      </Modal.Body>

      <Modal.Footer className="confirm-modal-footer">
        {!loading && !done && <Button
          variant="primary"
          className="modal-button"
          onClick={() => createProject()}
        >
          Submit
        </Button>}
        <Button
          variant="danger"
          className="modal-button"
          onClick={() => {props.secondButton(false); setDone(false)}}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TWCCreateCategory;
