import React, { useState, useEffect, useContext } from "react";
import ReadyOne from "../images/readyone.png";
import { VscAccount, VscThreeBars, VscChromeClose } from "react-icons/vsc";
import styles from "./Navbar.module.css";
import { Account, Quicklinks } from "./NavItems";
import { Link, useNavigate } from "react-router-dom";
import { AccountContext } from "../../auth";
import {ToastContext} from "../../layout";

export default function Navbar(props) {
  const [path, setPath] = useState("/Applications");
  const [quicklinksOpen, setQuicklinksOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const { firstName, logout } = useContext(AccountContext);
  const {notifyInfo, delay} = useContext(ToastContext);
  const navigate = useNavigate();

  const tabs = [
    {
      route: "/Applications",
      label: "Applications",
    },
    {
      route: "/How-To-Resources",
      label: "How-To Resources",
    },
    {
      route: "/Request-Form",
      label: "Request Form",
    },
  ];

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getActiveClass = (name) => (name === path ? styles.active : "");

  return (
    <nav className={styles.navbar}>
      <span className={styles.brandLogo}>ReadyOne<sup className={styles.trademark}>TM</sup></span>
      {width > 938 ? (
        <>
          <ul className={styles.navLinks}>
            {tabs.map((tab) => (
              <Link to={tab.route} onClick={() => setPath(tab.route)}>
                <li
                  className={`${styles.navLink} ${getActiveClass(tab.route)}`}
                >
                  <a className={styles.navText}>{tab.label}</a>
                </li>
              </Link>
            ))}
            <li
              className={`${styles.navLink} ${getActiveClass("/Quicklinks")}`}
            >
              <a
                className={styles.navText}
                onClick={() => setQuicklinksOpen(!quicklinksOpen)}
              >
                Quick Links
              </a>

              <Quicklinks
                setPath={setPath}
                path={path}
                show={quicklinksOpen}
                close={() => {
                  setTimeout(() => setQuicklinksOpen(false), 200);
                }}
              ></Quicklinks>
            </li>
          </ul>
          <ul className={styles.account}>
            <li>
              <span>{`Welcome, ${firstName}!`}</span>
            </li>
            <li>
              <VscAccount onClick={() => setAccountOpen(!accountOpen)} />
              <Account
                show={accountOpen}
                close={() => {
                  setTimeout(() => setAccountOpen(false), 200);
                }}
                email={props.email}
                firstName={props.firstName}
                lastName={props.lastName}
                setAuthenticated={props.setAuthenticated}
                permissions={props.permissions}
              ></Account>
            </li>
          </ul>
        </>
      ) : (
        <>
          <ul className={styles.navToggle}>
            <VscThreeBars onClick={() => setOpenNav(!openNav)} />
          </ul>
          <div className={`${styles.mobileNav} ${openNav && styles.open}`}>
            <VscChromeClose
              onClick={() => setOpenNav(false)}
              className={styles.close}
            />
            <span>{`Welcome, ${firstName}!`}</span>
            <ul className={styles.navLinks}>
              {tabs.map((tab) => (
                <Link to={tab.route} onClick={() => setPath(tab.route)}>
                  <li
                    className={`${styles.navLink} ${getActiveClass(tab.route)}`}
                  >
                    <a className={styles.navText}>{tab.label}</a>
                  </li>
                </Link>
              ))}
              <li
                className={`${styles.navLink} ${getActiveClass("/Quicklinks")}`}
              >
                <a
                  className={styles.navText}
                  onClick={() => setQuicklinksOpen(!quicklinksOpen)}
                >
                  Quick Links
                </a>

                <Quicklinks
                  setPath={setPath}
                  path={path}
                  show={quicklinksOpen}
                  close={() => {
                    setTimeout(() => setQuicklinksOpen(false), 200);
                  }}
                ></Quicklinks>
              </li>
              <li
                onClick={() => {
                  notifyInfo("Logging out...");
                  delay(1500).then(() => {
                    navigate("/");
                    logout();
                  })
                }}
                className={`${styles.navLink} ${styles.logout}`}
              >
                <a className={styles.navText}>Log Out</a>
              </li>
            </ul>
          </div>
        </>
      )}
    </nav>
  );
}
