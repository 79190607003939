import React, { useEffect, useState, useContext } from "react";
import styles from "./NotificationForm.module.css";
import Modal from "../Modal/Modal";
import {
  postNotification,
  getNotifications,
  deleteNotification,
} from "../../../../services/ReadyOneAPI";
import { ToastContext } from "../../../../layout";
import { format } from "date-fns-tz";

export default function NotificationForm({ show, close }) {
  const [startDate, setStartDate] = useState(
    format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx", {
      timeZone: "America/New_York",
    })
  );
  const [endDate, setEndDate] = useState(
    format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx", {
      timeZone: "America/New_York",
    })
  );
  const [message, setMessage] = useState("");
  const [autoClose, setAutoClose] = useState(false);
  const [type, setType] = useState("info");
  const [notifications, setNotifications] = useState([]);
  const { notifySuccess, notifyError } = useContext(ToastContext);

  const handleSubmit = () => {
    postNotification({ startDate, endDate, message, autoClose, type })
      .then((result) => {
        close();
        notifySuccess(result.replace(/^"|"$/g, ""));
        getNotifications().then((result) => {
          setNotifications(result);
          setStartDate(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx", {
            timeZone: "America/New_York",
          })
          setEndDate(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx", {
            timeZone: "America/New_York",
          })
          setMessage("")
          setAutoClose(false)
          setType("info")
        });
      })
      .catch((err) => {
        notifyError("Notification could not be posted at this time.");
      });
  };

  const handleDelete = (id) => {
    deleteNotification(id).then((result) => {
      notifySuccess(result.replace(/^"|"$/g, ""));
      getNotifications().then((result) => {
        setNotifications(result);
      });
    });
  };

  useEffect(() => {
    getNotifications().then((result) => {
      setNotifications(result);
    });
  }, [])

  return (
    <Modal
      id="notification-modal"
      show={show}
      title={"New Notification"}
      buttonText={"Create Notification"}
      onClick={() => {
        handleSubmit();
      }}
      hideModal={close}
    >
      <div className={styles.notifHolder}>
        <div className={styles.form}>
          <label>
            Start Date (EST):
            <input
              type="datetime-local"
              name="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={styles.inputField}
            />
          </label>
          <label>
            End Date (EST):
            <input
              type="datetime-local"
              name="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={styles.inputField}
            />
          </label>
          <label>
            Message:
            <textarea
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={styles.inputField}
            />
          </label>
          <label>
            Auto Close:
            <input
              type="checkbox"
              name="autoClose"
              checked={autoClose}
              onChange={(e) => setAutoClose(e.target.checked)}
              className={styles.inputField}
            />
          </label>
          <label>
            Type:
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              className={styles.inputField}
            >
              <option value="info">Info</option>
              <option value="success">Success</option>
              <option value="error">Error</option>
              <option value="warning">Warning</option>
            </select>
          </label>
        </div>
        <label>
          Active Notifications:
          <table className={styles.notificationTable}>
            <thead>
              <tr>
                <th>Notification</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification, index) => (
                <tr key={index}>
                  <td>{notification.message}</td>
                  <td>
                    <button
                      onClick={() => handleDelete(notification.id)}
                      className={styles.deleteButton}
                    >
                      X
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </label>
      </div>
    </Modal>
  );
}
