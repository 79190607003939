const apiURL = "https://elm-api.readyone.net";
const apiKey = "6yb9XMJvW781lU4Vg4cfb3TySGz076Ez1JQ0XBu0";

export function getModalData() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get/modaldata`, requestOptions)
    .then((response) => response.json())
    .then((body) => body.body);
}

export function getELMData() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch("https://elm-api.readyone.net/getELMData", requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function makeELMOAuthRequests() {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const requestUrl = new URL(
    "https://elm.readyone.net/jts/oauth-request-token"
  );
  const searchParams = requestUrl.searchParams;
  searchParams.append("oauth_consumer_key", "71fb687f1683406e8c8b94f4d9e667cb");
  searchParams.append("oauth_signature_method", "PLAINTEXT");
  searchParams.append("oauth_timestamp", "1678367952");
  searchParams.append("oauth_nonce", "WuZTyaeeDPt");
  searchParams.append("oauth_version", "1.0");
  searchParams.append("oauth_signature", "opshubpoc&");

  return fetch(requestUrl.toString(), requestOptions)
    .then((response) => response.text())
    .then((result) => {
      const oauthToken = result.split("&")[0].split("=")[1];
      const oauthTokenSecret = result.split("&")[1].split("=")[1];

      const authorizeUrl = `https://elm.readyone.net/jts/oauth-authorize?${result}`;

      window.open(authorizeUrl);

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const accessRequestUrl = new URL(
            "https://elm.readyone.net/jts/oauth-access-token"
          );
          const accessSearchParams = accessRequestUrl.searchParams;
          accessSearchParams.append(
            "oauth_consumer_key",
            "71fb687f1683406e8c8b94f4d9e667cb"
          );
          accessSearchParams.append("oauth_token", oauthToken);
          accessSearchParams.append("oauth_signature_method", "PLAINTEXT");
          accessSearchParams.append("oauth_timestamp", "1678368042");
          accessSearchParams.append("oauth_nonce", "I94iDC26oOe");
          accessSearchParams.append("oauth_version", "1.0");
          accessSearchParams.append(
            "oauth_signature",
            `opshubpoc&${oauthTokenSecret}`
          );
    
          fetch(accessRequestUrl.toString(), requestOptions)
            .then((response) => response.text())
            .then((result) => resolve({
              oauthToken: result.split('&')[0].split('=')[1],
              oauthTokenSecret: result.split('&')[1].split('=')[1]
            }))
            .catch((error) => reject(error));
        }, 2500);
      });
    })
    .catch((error) => console.log("error", error));
}
