import React from "react";
import "./PTBNavbar.css";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageHelper from "../../components/ImageHelper";

const DemoNavbar = (props) => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="navbar bottom-shadow"
        sticky="top"
      >
        <Navbar.Brand className="brand">
          <ImageHelper
            mode="newRO"
            width="5.5em"
            className="navbarbrand"
            onClick={() => {}}
          />
          <div className="d-inline" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-none" />
        
          
         
      </Navbar>
    </>
  );
};

export default DemoNavbar;
