import React, { useState, useRef, useEffect } from "react";
import { getFileNameFromKey } from "../Content";
import { IoIosArrowBack } from "react-icons/io";
import { BsSearch } from "react-icons/bs";
import styles from "./Header.module.css";

export default function Header({
  clickBack,
  activeMenu,
  getSearchResults,
  filePath,
}) {
  return (
    <div className={styles.container}>
      {getFileNameFromKey(activeMenu) !== "main" ? (
        <>
          <IoIosArrowBack
            className={styles.button}
            onClick={() => clickBack()}
            size="50px"
          />
          <div className={styles.title}>
            {getFileNameFromKey(activeMenu)}
            <div className={styles.filePath}>{filePath}</div>
          </div>
        </>
      ) : (
        "How-To Resources"
      )}

      <span className={styles.search}>
        <BsSearch />
        <input onChange={getSearchResults} placeholder="Search..." />
      </span>
    </div>
  );
}
