const api_key = "05OvDnN8BnaT2WMYme87d7xKcNC6m9NA1tymO76i";
const api_url = "https://innovator-api.readyone.net";

export const getInnovatorData = () => {
    var myHeaders = new Headers();
    myHeaders.append("X-Api-Key", api_key);
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    return fetch(`${api_url}/get-innovator-data`, requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));
}