export function getAppStreamURL(accessToken, application) {
  var myHeaders = new Headers();
  console.log(accessToken);
  myHeaders.append("Authorization", accessToken);
  //myHeaders.append("Content-Type", "application/json");
  let body = {
    application: application
  }

  let raw = JSON.stringify(body);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  return fetch(
    "https://ziz52cjaal.execute-api.us-gov-west-1.amazonaws.com/auth/auth",
    requestOptions
  ).then((response) => response.json());
}
