import React, { useState, useContext } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { submitFeedback } from "../../../services/ReadyOneAPI";
import { AccountContext } from "../../../auth";
import Select from "react-select";
import "../modals.css";

const UserFeedback = (props) => {
  const [showUserPermission, setShowUserPermission] = useState(false);
  const [showCreateProject, setShowCreateProject] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState([]);

  const [userSubject, setUserSubject] = useState("");
  const [userTopic, setUserTopic] = useState("");
  const [userExplain, setUserExplain] = useState("");

  const [showFeedback, setShowFeedback] = useState(false);
  const [showBug, setShowBug] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const { email } = useContext(AccountContext);

  const [topics] = useState([
    { value: "help", label: "Help" },
    { value: "feedback", label: "Feedback" },
    { value: "suggestion", label: "Suggestion" },
    { value: "bug", label: "Bug" },
  ]);

  const handleChange = (event) => {
    setUserTopic(event);
    console.log(selectedTopic);
  };

  const clickSubmit = () => {
    let feedback = {
      subject: userSubject,
      topic: userTopic,
      explanation: userExplain,
      email: email,
    };

    submitFeedback(feedback).then((result) => {
      console.log(result);
      if (result.statusCode === 200) setSubmitted(true);
    });
  };

  const getSuccessTemplate = () => {
    return (
      <div className="result-text">
        <div className="result-success">Success!</div>
        <div className="result-primary">
          Your feedback was successfully submitted
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.secondButton(false)}
        centered
      >
        <Modal.Header className="confirm-modal-header">
          <Modal.Title className={props.mode}>ReadyOne</Modal.Title>
        </Modal.Header>

        <Modal.Body className="confirm-modal-body form">
          <br />

          {!submitted ? (
            <>
              <Form.Group as={Col}>
                <Form.Label className="text-white">Subject</Form.Label>
                <Form.Control
                  onChange={(e) => setUserSubject(e.target.value)}
                  as="input"
                  className="explanationInput"
                ></Form.Control>
              </Form.Group>

              <br />
              <br />

              <Form.Group as={Col}>
                <Form.Label className="text-white">Topic</Form.Label>
                <Select
                  options={topics}
                  isMulti={false}
                  className="explanationInput"
                  onChange={(event) => {
                    setUserTopic(event);
                  }}
                />
              </Form.Group>

              <br />
              <br />

              <Form.Group as={Col}>
                <Form.Label className="text-white">Explanation</Form.Label>
                <Form.Control
                  onChange={(e) => setUserExplain(e.target.value)}
                  as="textarea"
                  isMulti={true}
                  className="explanationInput"
                  input-height-inner="flex-grow-1"
                ></Form.Control>
              </Form.Group>
            </>
          ) : (
            getSuccessTemplate()
          )}
        </Modal.Body>

        <Modal.Footer className="feedback-modal-footer">
          {!submitted ? (
            <Button
              variant="danger"
              className="modal-button"
              onClick={() => clickSubmit()}
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="danger"
              className="modal-button"
              onClick={() => {
                props.secondButton();
                setSubmitted(false);
              }}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserFeedback;
