import React from "react";
import styles from "./Modal.module.css";

export default function GoodModal({ show, title, children, onClick, hideModal, submitted, invalidEmail }) {
  return (
    <div className={`${styles.modal} ${show ? styles.show : styles.hide}`}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <h2>{title}</h2>
        </div>
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          {!submitted && <button className={invalidEmail && styles.shake} onClick={onClick}>Request Access</button>}
          <button onClick={hideModal}>Close</button>
        </div>
      </div>
    </div>
  );
}
