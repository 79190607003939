import {
  BsFillFileEarmarkPlayFill,
  BsFillFileEarmarkTextFill,
  BsFileEarmarkZip,
  BsFileEarmarkPptFill,
  BsFillFileEarmarkWordFill,
} from "react-icons/bs";
import styles from "./FileItems.module.css";

export function SearchItem({ file }) {
  let source = file._source;
  return (
    <button
      className={styles.file}
      onClick={() => window.open(encodeURI(source.url))}
    >
      <div className={styles.content}>
        {getFileIcon(source.name)}
        <span>{source.name}</span>
      </div>
    </button>
  );
}

export function FileItem({ file, path }) {
  return (
    <button className={styles.file} onClick={() => window.open(path)}>
      <div className={styles.content}>
        {getFileIcon(file.name)}
        <span>{file.name}</span>
      </div>
    </button>
  );
}

export function FolderItem({ file, setActiveMenu }) {
  return (
    <button className={styles.file} onClick={() => setActiveMenu(file.key)}>
      <span>{file.name}</span>
    </button>
  );
}

const extIcon = (ext) => {
  switch (ext) {
    case "pptx":
      return <BsFileEarmarkPptFill />;
      break;
    case "docx" || "doc":
      return <BsFillFileEarmarkWordFill />;
      break;
    case "mp4":
      return <BsFillFileEarmarkPlayFill />;
      break;
    case "zip":
      return <BsFileEarmarkZip />;
      break;
    default:
      return <BsFillFileEarmarkTextFill />;
  }
};

const getFileIcon = (file) => {
  let split = file.split(".");
  let ext = split[split.length - 1];
  return extIcon(ext);
};
